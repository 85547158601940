import React, { Fragment, useContext, useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Nodata,
  NodataDesc,
  NodataMedia,
  Section,
  SectionTitle,
} from "../components/Section"
import { Context } from "../components/context"
import styled from "styled-components"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import commaNumber from "comma-number"
import PrimaryButton from "../components/Button/PrimaryButton"
import CloseIcon from "../components/Icons/CloseIcon"
import QuotePopup from "../components/QuotePopup"
import DefaultButton from "../components/Button/DefaultButton"
import { QuoteIcon } from "../components/Icons"
import { graphql, Link } from "gatsby"
import ProductList from "../components/ProductList"
import QuotePopupProduct from "../components/QuotePopupProduct"
import LikeProductCarousel from "../components/LikeProductCarousel"

const ComparisonTableData = styled.div``

const ComparisonTable = styled.table`
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  tr {
    border: 0;
    border-bottom: 1px solid #f7f7f7;
  }
  tr:first-child {
    border-top: 1px solid #f7f7f7;
  }

  td:first-child {
    width: auto;
  }

  td.pro-heading {
    text-align: right;
    font-weight: 500;
    background: #ff7245;
    color: #fff;
    border-right: 2px solid #f7f7f7;
    width: 100px;
    @media (min-width: 768px) {
      width: 150px;
    }
  }
  td.pro-title {
    padding: 8px 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    background-color: #ebf6ff;
    text-align: left;
    color: #062c44;
  }
  td {
    padding: 8px 10px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: left;
    background: #deedfd;
    font-size: 14px;
    line-height: 22px;
    color: #062c44;
  }
  td.pro-desc {
    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  td.product-thumb {
    text-align: center;
    .gatsby-image-wrapper,
    img {
      width: 200px;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
  td:nth-child(2n + 3) {
    background: #cae2fc;
  }
  td.action {
    min-width: 250px;
    text-align: center;
    .btn {
      font-size: 14px;
      line-height: 20px;
      padding: 3px 9px;
      font-weight: 400;
    }
  }
`

const ActionCell = styled.td`
  padding: 10px 0 !important;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  span.btn {
    padding: 4px 10px;
  }
`

export const ProductTitle = styled.div`
  position: relative;
  padding: 4px 40px 4px 4px;
`

export const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`

const ProductItem = styled.div`
  position: relative;
  height: 100%;
  padding: 4px;

  img {
    padding: 4px;
    line-height: 1.62857143;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
`

const Title = styled.div`
  color: black;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 32px;
`

const CloseWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const CloseBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 12px;
  border: 1px solid #062c44;
  svg {
    fill: #062c44;
    width: 10px;
  }
  &:hover {
    border-color: #ff3e00;
    svg {
      fill: #ff3e00;
    }
  }
`

const AddProduct = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  min-height: 120px;

  span {
    font-size: 16px;
    color: gray;
  }
`

const AddProductIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  span {
    font-size: 24px;
    font-weight: bold;
  }
`
const displayFields = [
  "Product",
  "Price",
  "Building Dimensions (WxL)",
  "Total Building Area",
  "Roof Style",
  "SKU",
  "Available for Finance",
  "Available for RTO",
  "Rust Through Warranty",
  "Workmanship Warranty",
  "Free Delivery and Installation",
  "Actions",
]

const ProductsCompare = ({ states, location }) => {
  const {
    comparisonData,
    compareProducts,
    removeProduct,
    addProductToComparison,
    selectProduct,
  } = useContext(Context)
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  const renderCell = (field, product, no) => {
    if (!product) {
      if (field !== "Product") {
        if (!compareProducts || compareProducts.length === 0) return null

        return <td key={no}></td>
      }
    }

    switch (field) {
      case "Product":
        return (
          <td key={no}>
            {product ? (
              <ProductItem>
                <CloseWrap>
                  <CloseBtn onClick={() => removeProduct(product.id)}>
                    <CloseIcon />
                  </CloseBtn>
                </CloseWrap>
                <Title>{product.productName}</Title>
                <GatsbyImage
                  image={product.productImages.gatsbyImageData}
                  alt={product.productName}
                />
              </ProductItem>
            ) : (
              <AddProduct
                onClick={async () => {
                  const p = await selectProduct()
                  addProductToComparison("select", p, no)
                }}
              >
                <AddProductIconWrapper>
                  <span>+</span>
                </AddProductIconWrapper>
                <span>Add a Product</span>
              </AddProduct>
            )}
          </td>
        )
      case "Price":
        return (
          <td key={no}>
            <strong>${commaNumber(product.price)}</strong>
          </td>
        )
      case "Building Dimensions (WxL)":
        return <td key={no}>{`${product.width}'x${product.length}'`}</td>
      case "Total Building Area":
        return <td key={no}>{`${product.width * product.length} (Sq. Ft.)`}</td>
      case "Roof Style":
        return <td key={no}>{product.productRoofStyle.name}</td>
      case "SKU":
        return <td key={no}>{product.productSku}</td>
      case "Available for Finance":
        return <td key={no}>{product.applicableForFinancing ? "Yes" : "No"}</td>
      case "Available for RTO":
        return <td key={no}>{product.applicableForRto ? "Yes" : "No"}</td>
      case "Rust Through Warranty":
        return <td key={no}>20 Years</td>
      case "Workmanship Warranty":
        return <td key={no}>30 Days</td>
      case "Free Delivery and Installation":
        return <td key={no}>Yes/No</td>
      case "Actions":
        return (
          <ActionCell key={no}>
            <Actions>
              <button type="button" onClick={() => setQuoteVisible(true)}>
                <DefaultButton text="Request Quote" />
              </button>
              <Link to={product.url} aria-label={product.productName}>
                <PrimaryButton text="View Details" />
              </Link>
            </Actions>
          </ActionCell>
        )
      default:
        return null
    }
  }

  const renderHeadLabel = field => {
    if (field === "Product") {
      return <td className="pro-heading">Product</td>
    }

    if (!compareProducts || compareProducts.length === 0) return null
    return <td className="pro-heading">{field}</td>
  }

  return (
    <ComparisonTableData>
      <ComparisonTable>
        <tbody>
          {displayFields.map((field, j) => (
            <tr key={j}>
              {renderHeadLabel(field)}
              {comparisonData.map((p, i) => renderCell(field, p, i))}
            </tr>
          ))}
        </tbody>
      </ComparisonTable>
      <QuotePopup
        states={states}
        location={location}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
      />
    </ComparisonTableData>
  )
}

const SimilarProducts = ({ data, states, location }) => {
  const { compareProducts } = useContext(Context)
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  if (!compareProducts || compareProducts.length === 0) return null

  const compareProductIds = compareProducts.map(p => p.id)
  const allProducts = data.allContentfulProduct.edges
  const categories = compareProducts.map(p => p.productCategory.name)
  const ps = allProducts.filter(
    p =>
      categories.indexOf(p.node.productCategory.name) >= 0 &&
      compareProductIds.indexOf(p.node.id) < 0
  )

  return (
    <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
      <div className="container">
        <SectionTitle maxWidth="550px" mb="30px">
          You May Also Like
        </SectionTitle>
        <LikeProductCarousel
          relatedProductsData={ps}
          openQuoteModal={() => setQuoteVisible(true)}
        />
      </div>
      <QuotePopupProduct
        states={states}
        location={location}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
      />
    </Section>
  )
}

const comparePage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO
        title="Metal Building Comparison"
        description="Metal Building Comparison"
      />
      <Section pt="200px" pb="90px" xpt="54px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <h1 style={{ textAlign: "center", margin: "10px 0px" }}>
            Product Comparison
          </h1>
          <ProductsCompare
            location={location}
            states={data.allContentfulState.edges}
          />
        </div>
      </Section>
      <SimilarProducts
        data={data}
        location={location}
        states={data.allContentfulState.edges}
      />
    </Layout>
  )
}

export default comparePage

export const pageQuery = graphql`
  query ComparePageQuery {
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData(quality: 100)
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
  }
`
