import React, { useContext, useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import commaNumber from "comma-number"

import {
  PhoneIcon,
  WidthIcon,
  LenghtIcon,
  HeightIcon,
  TickIcon,
  QuoteIcon,
} from "../Icons"
import PrimaryButton from "../Button/PrimaryButton"
import PrimaryLinkButton from "../Button/PrimaryLinkButton"
import DefaultButton from "../Button/DefaultButton"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import { GatsbyImage } from "gatsby-plugin-image"
import { Context } from "../context"

const CardProduct = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  &:before {
    content: "";
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    top: 25%;
    border: 1px solid #d3d3d3;
    ${BreakpointUp.lg`				
            width: 70%;
            top: 0;
            border:none;
        `}
  }
  ${BreakpointUp.lg`				
        flex-direction: row-reverse;	
    `}
`
const CardProductHeading = styled.div`
  padding: 15px;
  ${BreakpointUp.sm`	
        padding:30px;
    `}
  ${BreakpointUp.xl`	
        padding:30px 50px;
    `}

  button {
    margin-top: 1rem;
  }
`
const CardProductTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 5px;
  ${BreakpointUp.lg`	
        margin: 0 0 10px;  
        font-size: 24px;
        line-height: 34px;
    `}
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const CardProductFigure = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;

  ${BreakpointUp.lg`		
        padding: 30px 0;
        flex-direction: row;
        flex: 0 0 50%;
        max-width: 50%;
    `}
  ${BreakpointUp.xl`
        padding: 50px 0;
    `}
    .gatsby-image-wrapper {
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 12px;
    img {
      transform-origin: 65% 75%;
      border-radius: 12px;
      transition: transform 1s, filter 0.5s ease-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`

const ProductSize = styled.ul`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  position: relative;
  z-index: 1;
  flex-direction: row;
  margin-bottom: 0;
  width: 100%;
  justify-content: space-around;
  border-bottom: 1px solid #ebedf3;
  padding: 15px;
  ${BreakpointUp.sm`	
        padding:15px 30px;
    `}
  ${BreakpointUp.lg`
        width: auto;
        padding:0;
        margin: 0 0 0 30px;
        flex-direction:column;
        border-bottom:none;
    `}
`
const ProductItemSize = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  ${BreakpointUp.lg`
        flex-direction: column;
        margin:10px 0;
    `}
  .icon {
    display: inline-flex;
    svg {
      width: 40px;
      ${BreakpointUp.sm`		
                width:auto;
            `}
    }
    + .text {
      margin-left: 8px;
      ${BreakpointUp.lg`		
                margin-left:0;
            `}
    }
  }
  .text {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    flex-direction: column;
    ${BreakpointUp.lg`		
            align-items: center; 
            flex-direction: row;
        `}
    small {
      color: #555;
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      ${BreakpointUp.sm`		
                font-size:14px;              
                line-height:24px
            `}
      + strong {
        margin-left: 0;
        ${BreakpointUp.lg`		
                    margin-left:5px;
                `}
      }
    }
    strong {
      display: block;
      color: #000;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      ${BreakpointUp.sm`		
                font-size:20px;
                line-height: 30px;
            `}
    }
  }
`
const CardProductBody = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  ${BreakpointUp.lg`		
        flex: 0 0 50%;
        max-width: 50%;
	`}
`

const FinanceOption = styled.div`
  border-top: 1px solid #ebedf3;
  border-bottom: 1px solid #ebedf3;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0;
`
const FinanceType = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 14px;
  ${BreakpointUp.lg`		
        font-size:16px;
	`}
`
const FinanceInfo = styled.div`
  padding: 0 15px;
  ${BreakpointUp.xl`	
        padding:0 25px;
    `}
`
const FinanceItem = styled.div`
  display: ${props => props.display};
  align-items: center;
  opacity: 0.5;
  color: #000;
  position: relative;
  padding: 0 15px 0 25px;
  ${BreakpointUp.sm`		
        padding: 0 25px 0 50px;         
	`}
  ${BreakpointUp.xl`		
        padding: 0 25px 0 50px;         
	`}
    &:last-child {
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
  .icon {
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 3px solid #dfe3ef;
    ${BreakpointUp.sm`		
            width: 30px;
            height: 30px;
            border: 6px solid #DFE3EF;
        `}
    + .text {
      margin-left: 5px;
    }
    svg {
      fill: #fc0002;
      width: 8px;
      ${BreakpointUp.sm`		
                width:12px;
            `}
    }
  }
  &:after,
  &:before {
    left: 0;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #f7f7f7;
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #dee1ea;
    border-width: 13px;
    margin-top: -13px;
  }
  &.active {
    opacity: 1;
    &:after,
    &:before {
      opacity: 1;
    }
  }
`
const FinancePane = styled.div`
  display: ${props => props.display};
  .label {
    color: #555;
    font-weight: 500;
    margin: 0;
    font-size: 14px;
  }
  button {
    color: #fc0002;
    font-size: 20px;
    font-weight: bold;

    .icon {
      line-height: 20px;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      svg {
        fill: #fc0002;
        ${BreakpointUp.sm`      
                    width: 16px;
                `}
      }
    }
    &:hover,
    &:focus {
      color: #0b619b;
      svg {
        fill: #0b619b;
      }
    }
  }
`

const ProductSku = styled.div`
  color: #555;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;

  span {
    font-weight: 500;
  }
`
const Price = styled.div`
  small {
    color: #555;
    font-size: 20px;
    ${BreakpointUp.lg`		
            font-size:24px;            
        `}
  }
  strong {
    color: #b30000;
    font-weight: 700;
    font-size: 24px;
    ${BreakpointUp.lg`		
            font-size:30px;            
        `}
  }
`
const ListPayment = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 -5px;
  ${BreakpointUp.sm`		
        margin: 0 -10px;        
    `}
`
const ListItemPayment = styled.li`
  margin-bottom: 0;
  padding: 0 5px;
  ${BreakpointUp.sm`		
        padding: 0 10px;
    `}
  strong {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    ${BreakpointUp.sm`		
            font-size:16px;
            line-height: 20px;
        `}
  }
  span {
    display: block;
    color: #555;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    ${BreakpointUp.sm`		
            font-size:14px;
            line-height: 20px;
        `}
  }
`

const ButtonToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;
  padding: 20px;
  flex-direction: column;
  ${BreakpointUp.sm`  
        flex-direction: row;
    `}
  ${BreakpointUp.lg`    
        padding:40px 15px;
    `} 
    a, button {
    padding: 0 10px;
    ${BreakpointDown.sm`    
            width:100%;
        `}
    + a, + button {
      ${BreakpointDown.sm`
                margin-top:15px;                
            `}
    }
  }
  .btn {
    ${BreakpointDown.sm`    
            width:100%;
        `}
  }
`
const HelpBlock = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  color: #555;
  font-weight: 500;
  width: 100%;
  z-index: 1;
  line-height: 26px;
  font-size: 12px;
  display: none;
  ${BreakpointUp.lg`    
        display:block;
    `}
  ${BreakpointUp.xl`    
        font-size:14px;
    `}
    a {
    color: #fc0002;
    display: inline-flex;
    align-items: center;
    line-height: 26px;
    font-size: 12px;
    ${BreakpointUp.xl`    
            font-size:14px;
        `}
    svg {
      fill: #fc0002;
    }
    .text {
      line-height: 20px;
      + .icon {
        margin-left: 5px;
      }
    }
    .icon {
      line-height: 20px;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &:hover,
    &:focus {
      color: #0b619b;
      svg {
        fill: #0b619b;
      }
    }
  }
`

const AddCompareBtn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: red;
  font-size: 20px;
`

const PlusIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  color: white;
  font-size: 20px;
`

const ProductCard = ({ data, openQuoteModal }) => {
  const [finance, setFinance] = useState("rto");
  const { addProductToComparison } = useContext(Context)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            loanPrice {
              apr_36
              apr_48
              apr_60
            }
          }
        }
      }
    `
  )

  const rto_36_price = (
    data.price / site.siteMetadata.loanPrice.apr_36
  ).toFixed(2)
  const rto_48_price = (
    data.price / site.siteMetadata.loanPrice.apr_48
  ).toFixed(2)
  const rto_60_price = (
    data.price / site.siteMetadata.loanPrice.apr_60
  ).toFixed(2)

  return (
    <CardProduct className="card">
      <CardProductFigure className="card-img">
        <Link to={data.url} aria-label={data.productName}>
          <GatsbyImage
            image={data.productImages.gatsbyImageData}
            alt={data.productName}
          />
        </Link>
        <ProductSize className="measurement">
          <ProductItemSize>
            <span className="icon">
              <WidthIcon />
            </span>
            <span className="text">
              <small>Width</small>
              <strong>{data.width}</strong>
            </span>
          </ProductItemSize>
          <ProductItemSize>
            <span className="icon">
              <LenghtIcon />
            </span>
            <span className="text">
              <small>Length</small>
              <strong>{data.length}</strong>
            </span>
          </ProductItemSize>
          <ProductItemSize>
            <span className="icon">
              <HeightIcon />
            </span>
            <span className="text">
              <small>Height</small>
              <strong>{data.height}</strong>
            </span>
          </ProductItemSize>
        </ProductSize>
        <HelpBlock>
          *Price might be vary with states and certification requirements{" "}
        </HelpBlock>
      </CardProductFigure>
      <CardProductBody className="card-body">
        <CardProductHeading>
          <ProductSku>
            <span>SKU No:</span> {data.productSku}
          </ProductSku>
          <Link to={data.url} aria-label={data.productName}>
            <CardProductTitle className="card-title">
              {data.productName}
            </CardProductTitle>
          </Link>
          <Price>
            <small>Starting Price :</small>{" "}
            <strong>${commaNumber(data.price)}*</strong>
          </Price>
          {/* <button className="d-none" type="button" onClick={() => addProductToComparison("card", data)}>
            <AddCompareBtn>
              <PlusIcon>+</PlusIcon>
              {` `}Compare
            </AddCompareBtn>
          </button> */}
        </CardProductHeading>
        <FinanceOption className="finance-option">
          <FinanceType>
            <FinanceItem
              display={data.applicableForRto ? "flex" : "none"}
              className={finance === "rto" ? "active" : ""}
              onClick={() => setFinance("rto")}
            >
              <span className="icon">
                <TickIcon />
              </span>
              <span className="text">RTO Available </span>
            </FinanceItem>
            <FinanceItem
              display={data.applicableForFinancing ? "flex" : "none"}
              className={finance === "financing" ? "active" : ""}
              onClick={() => setFinance("financing")}
            >
              <span className="icon">
                <TickIcon />
              </span>
              <span className="text">Financing Available</span>
            </FinanceItem>
          </FinanceType>
          <FinanceInfo>
            <FinancePane
              display={
                finance === "rto" && data.applicableForRto ? "block" : "none"
              }
            >
              <div className="label">Monthly RTO Payment</div>
              <ListPayment>
                <ListItemPayment>
                  <strong>${rto_36_price}</strong>
                  <span>36 Months</span>
                </ListItemPayment>
                <ListItemPayment>
                  <strong>${rto_48_price}</strong>
                  <span>48 Months</span>
                </ListItemPayment>
                <ListItemPayment>
                  <strong>${rto_60_price}</strong>
                  <span>60 Months</span>
                </ListItemPayment>
              </ListPayment>
            </FinancePane>
            <FinancePane
              display={
                finance === "financing" && data.applicableForFinancing
                  ? "block"
                  : "none"
              }
            >
              <Link to="/financing" aria-label="apply now">
                <PrimaryLinkButton text="Apply Now" />
              </Link>
            </FinancePane>
          </FinanceInfo>
        </FinanceOption>
        <ButtonToolbar>
          <a href="tel:8666817846" aria-label="(866) 681-7846">
            <PrimaryButton icon={<PhoneIcon />} text="(866) 681-7846" />
          </a>
          <button type="button" onClick={openQuoteModal}>
            <DefaultButton icon={<QuoteIcon />} text="Request A Quote" />
          </button>
        </ButtonToolbar>
      </CardProductBody>
    </CardProduct>
  )
}

export default ProductCard
