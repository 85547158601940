import styled from "styled-components"
import { ModalBody } from "../Modal"

export const ComparisonModalBody = styled(ModalBody)`
  padding-top: 12px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
`

export const ProductList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ProductItem = styled.div`
  position: relative;
  width: 50%;
  padding: 12px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (min-width: 768px) {
    width: 25%;
  }
`

export const RemoveButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 12px;

  svg {
    width: 14px;
    fill: #cc2c2c;
  }
`

export const ProductTitle = styled.span`
  font-size: 14px;
  line-height: 12px;
  color: black;
`

export const ProductPrice = styled.span`
  font-size: 14px;
  line-height: 12px;
  font-weight: bold;
  color: green;
`

export const AddProduct = styled.div`
  cursor: pointer;
  width: 50%;
  padding: 12px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: 25%;
  }

  span {
    font-size: 20px;
    color: black;
  }
`

export const PlusIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  background: blue;
  color: white;
`

export const ProductActions = styled.div`
  min-width: 280px;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  column-gap: 3rem;
`

export const PushButton = styled.button`
  text-transform: uppercase;
  color: #bc6200;
  font-size: 16px;
`
