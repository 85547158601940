// import React, { useState, useEffect } from "react"
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SocialNetwork from "../SocialNetwork"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import Address from "../Address"
import StateDrop from "../StateDrop"
import RequestQuote from "../RequestQuote"
// import DefaultButton from "../Button/DefaultButton"
// import VeteransDayPopup from "../VeteransDayPopup"
//import BBB from "../Images/BBB"

const FooterWrapper = styled.footer`
  border-top: 1px solid #dbdbdb;
  overflow: hidden;
  position: relative;
`
const FooterTop = styled.div`
  padding: 0;
  position: relative;
  z-index: 1;
  padding-top: ${props => props.pt};
  &:after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 0;
    overflow: hidden;
    background: #fff;
    width: 780px;
    height: 780px;
    right: -390px;
    border: 140px solid rgba(221, 228, 240, 0.5);
    ${BreakpointUp.xl`
      display: block;
    `}
  }
`
const MenuGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`
const MenuItemGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
  ${BreakpointUp.md`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-bottom:none;
    margin-bottom:0;
	`}
`
const MenuTitle = styled.div`
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
  > a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const MenuList = styled.ul`
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  ${BreakpointUp.sm` 
    columns: 2;
    column-gap: 5px;
	`}
  ${BreakpointUp.md` 
    columns: 1;
    column-gap: 0px;
    margin:0;
	`}
`
const MenuItem = styled.li`
  margin-bottom: 0;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 5px 0;
  padding: 0;
  ${BreakpointUp.md` 
    padding: 5px 0;
	`}
  & a {
    color: #555;
    display: inline-block;
    &:hover {
      color: #000;
    }
  }
`
const FooterGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
`
const FooterLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.xl`    
    flex: 0 0 75%;
    max-width: 75%;
  `}
`
const FooterRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.xl`    
    flex: 0 0 25%;
    max-width: 25%;
  `}
`
// const FooterInfoGrid = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   .list-info{
//     margin:0;
//   }
//   .bbb {
//     margin: 15px auto;
//     padding: 0;
//     display: block;
//     position: relative;
//     overflow: hidden;
//     width: 200px;
//     height: 90px;
//     outline: none;
//       img {
//         padding: 0px;
//         border: none;
//         position: absolute;
//         top: 0;
//         bottom: 0;
//         left: 0;
//         right: 0;
//         max-width: inherit;
//       }
//   }
// `

const FooterMiddle = styled.div`
  position: relative;
  z-index: 1;
`
const FooterBottom = styled.div`
  color: rgba(102, 102, 102, 0.7);
  position: relative;
  z-index: 1;
`
const CopyrightGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  ${BreakpointUp.xl`
    padding:45px 0;    
  `}
  ${BreakpointDown.sm`    
    justify-content: center;
    grid-template-columns: repeat(1, auto);
  `}
`
const Copyright = styled.div`
  font-size: 14px;
  ${BreakpointDown.sm`    
    margin-bottom:10px;
  `}
  & a {
    color: rgba(102, 102, 102, 0.7);
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
      color: #000;
    }
  }
`
const Accredited = styled.div`
  position: fixed;
  left: 5px;
  top: 95px;
  z-index: 10;
  overflow: hidden;
  width: 56px;
  height: 104px;
  outline: none;
  display: none;
  ${BreakpointUp.xl`    
    top: 160px;
  `}
  ${BreakpointUp.md`    
    display: block;
  `}
  & a {
    display: block;
  }
`
const Footer = ({ location, noFooterForm }) => {
  // const [isVisibleSale, setSaleVisible] = useState(false)
  // useEffect(() => {
  //   setTimeout(() => setSaleVisible(true), 3000);
  // }, []);

  // if (typeof window !== `undefined`) {
  //   if (isVisibleSale === true) {
  //     document.body.classList.add("modal-open")
  //   } else {
  //     document.body.classList.remove("modal-open")
  //   }
  // }
  return (
    <>
      {/* { setSaleVisible ?  <VeteransDayPopup location={location} isVisible={isVisibleSale} onClose={() => setSaleVisible(false)} /> : null } */}
      <FooterWrapper>
        {!noFooterForm && <RequestQuote location={location} />}

        <FooterTop pt={noFooterForm ? "50px" : "0"}>
          <div className="container">
            <FooterGrid>
              <FooterLeft>
                <MenuGrid>
                  <MenuItemGrid>
                    <MenuTitle>
                      <Link to="/metal-carports">Carports</Link>
                    </MenuTitle>
                    <MenuList>
                      <MenuItem>
                        <Link to="/a-frame-horizontal-carports">
                          A-Frame Horizontal
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/a-frame-vertical-carports">
                          A-Frame Vertical
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/regular-style-carports">
                          Regular Style Carports
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/one-car-carports">One Car Carports</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/two-car-carports">Two Car Carports</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/three-car-carports">Three Car Carports</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/deluxe-carports">
                          Deluxe Enclosed Carports
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/rv-carports">RV Carports</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/boat-garages-sheds">
                          Boat Garages and Sheds
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/utility-carports">Utility Carports</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/camper-carports-shelters">
                          Camper Carports
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/metal-carport-prices">
                          Metal Carport Prices
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItemGrid>

                  <MenuItemGrid>
                    <MenuTitle>
                      <Link to="/metal-garages">Metal Garages</Link>
                    </MenuTitle>
                    <MenuList>
                      <MenuItem>
                        <Link to="/a-frame-horizontal-garages">
                          A-Frame Horizontal Garages
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/a-frame-vertical-garages">
                          A-Frame Vertical Garages
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/regular-style-garages">
                          Regular Style Garages
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/one-car-garages">One Car Garages</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/two-car-garages">Two Car Garages</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/three-car-garages">Three Car Garages</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/metal-garage-prices">
                          Metal Garage Prices
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/rv-garages">RV Garages</Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItemGrid>
                  <MenuItemGrid>
                    <MenuTitle>
                      <Link to="/metal-buildings">Metal Buildings</Link>
                    </MenuTitle>
                    <MenuList>
                      <MenuItem>
                        <Link to="/metal-building-homes">
                          Metal Building Homes
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/clear-span-buildings">
                          Clear-Span Buildings
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/colonial-buildings">Colonial Buildings</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/custom-buildings">Custom Buildings</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/deluxe-buildings">Deluxe Buildings</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/metal-sheds">Sheds</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/storage-units">Storage Buildings</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/warehouses">Warehouses</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/workshop-buildings">Workshops</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/barndominiums">Barndominiums</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/metal-building-prices">
                          Metal Building Prices
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItemGrid>
                  <MenuItemGrid>
                    <MenuTitle>
                      <Link to="/metal-barns">Barn Buildings</Link>
                    </MenuTitle>
                    <MenuList>
                      <MenuItem>
                        <Link to="/regular-style-barns">
                          Regular Style Barns
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/horizontal-roof-barns">
                          Horizontal Roof Barns
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/vertical-roof-barns">
                          Vertical Roof Barns
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/horse-barns">Horse Barns</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/gambrel-barns">Gambrel Barns</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/raised-center-barns">
                          Raised Center Barns
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/straight-roof-barns">
                          Straight Roof Barns
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/loafing-sheds">Loafing Sheds</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/horse-stalls-shelters">
                          Horse Stalls and Shelters
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/riding-arenas">Riding Arenas</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/metal-barn-prices">Metal Barn Prices</Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItemGrid>
                  <MenuItemGrid>
                    <MenuTitle>Quick Links</MenuTitle>
                    <MenuList>
                      <MenuItem>
                        <Link to="/rent-to-own-buildings">Rent-To-Own</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/financing">Financing</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/3d-metal-building-color-planner">
                          3D Color Planner
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/color-options">Color Samples</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/frequently-asked-questions">FAQs</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/articles">Blog</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/infographics">Infographics</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/reviews">Reviews</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/about-us">About Us</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/contact">Contact Us</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/sitemap">Sitemap</Link>
                      </MenuItem>
                    </MenuList>
                  </MenuItemGrid>
                </MenuGrid>
              </FooterLeft>
              <FooterRight>
                <Address />
              </FooterRight>
            </FooterGrid>
          </div>
        </FooterTop>
        <FooterMiddle>
          <div className="container">
            <StateDrop />
          </div>
        </FooterMiddle>
        <FooterBottom>
          <div className="container">
            <CopyrightGrid>
              <Copyright>
                Copyright © {new Date().getFullYear()} Coast to Coast Carports.
                All Rights Reserved.{" "}
                <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
                <Link to="/shopping-policy">Shopping Policy</Link> |{" "}
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </Copyright>
              <SocialNetwork />
            </CopyrightGrid>
          </div>
        </FooterBottom>
        <Accredited>
          {/* <a href="https://www.bbb.org/us/ar/knoxville/profile/carport/coast-to-coast-carports-0935-4000772#bbbseal" target="_blank" rel="noreferrer" title="Coast to Coast Carports, Inc., Carport, Knoxville, AR"><BBB /></a> */}
        </Accredited>
      </FooterWrapper>
    </>
  )
}

export default Footer
