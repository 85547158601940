import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrimaryButton from "../components/Button/PrimaryButton"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { v4 } from "uuid"
import {
  Section,
  SectionTitle,
  SectionLead,
  MarkdownContent,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import Badge from "../components/Badge"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import MapStyle from "../components/MapStyle/index"
import ListArrow from "../images/BulletIcon.svg"
import LocationStyle from "../components/LocationStyle"

const ListAreas = styled.ul`
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  column-count: 2;
  column-fill: balance;
  ${BreakpointUp.sm`
        column-count: 3;
    `}
  ${BreakpointUp.lg`
        column-count: 4;
    `}
`
const ListItem = styled.li`
  padding-left: 20px;
  vertical-align: middle;
  margin: 0;
  line-height: 26px;
  + li {
    margin-top: 10px;
  }
  &:before {
    content: url(${ListArrow});
    display: inline-block;
    margin-left: -20px;
    width: 20px;
    vertical-align: top;
  }
  a {
    color: #000;
    font-weight: 700;
    line-height: 26px;
    display: inline-block;
    &:hover {
      color: #fc0002;
    }
  }
`
const ListBadge = styled.div`
  margin-bottom: 0;
  /* overflow: hidden;
  max-height: 180px; */
`
const LeftContent = styled.div`
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const LoadMore = styled.button`
  display: block;
  margin: 0 auto;
`

const State = ({ data, location, pageContext: { category } }) => {
  const [limit, Setlimit] = useState(20)
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  const pageData = data.contentfulState
  const productCategory = category
  const relatedProductsData = []

  data.allContentfulProduct.edges.map(product => {
    if (
      product.node.productCategory.name.toLowerCase() ===
      productCategory.split("-").join(" ")
    ) {
      relatedProductsData.push(product.node)
    }
  })

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const myParam = searchParams.get("appointment")
    setQuoteVisible(myParam === "")
  }, [])

  return (
    <Layout location={location}>
      <Seo
        title={`𝐌𝐞𝐭𝐚𝐥 𝐁𝐮𝐢𝐥𝐝𝐢𝐧𝐠𝐬 In ${pageData.name} - 𝟑𝟎𝟎+ Custom Options Available`}
        description={`Buy a wide range of steel buildings in ${pageData.name}. Choose from over 300+ designs for your storage and vehicle protection needs.`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>{pageData.name}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="60px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0 overflow-initial"
      >
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal Buildings ${pageData.name}`}</h1>
                {pageData.abbreviation !== "AL" &&
                  pageData.abbreviation !== "AZ" &&
                  pageData.abbreviation !== "AR" &&
                  pageData.abbreviation !== "CA" &&
                  pageData.abbreviation !== "CO" &&
                  pageData.abbreviation !== "FL" &&
                  pageData.abbreviation !== "IL" &&
                  pageData.abbreviation !== "IN" &&
                  pageData.abbreviation !== "KS" &&
                  pageData.abbreviation !== "KY" &&
                  pageData.abbreviation !== "LA" &&
                  pageData.abbreviation !== "MS" &&
                  pageData.abbreviation !== "MO" &&
                  pageData.abbreviation !== "MI" &&
                  pageData.abbreviation !== "NM" &&
                  pageData.abbreviation !== "NY" &&
                  pageData.abbreviation !== "NC" &&
                  pageData.abbreviation !== "OH" &&
                  pageData.abbreviation !== "OK" &&
                  pageData.abbreviation !== "PA" &&
                  pageData.abbreviation !== "SC" &&
                  pageData.abbreviation !== "TN" &&
                  pageData.abbreviation !== "TX" &&
                  pageData.abbreviation !== "UT" &&
                  pageData.abbreviation !== "VA" &&
                  pageData.abbreviation !== "WA" &&
                  pageData.abbreviation !== "WV" &&
                  pageData.abbreviation !== "OR" && (
                    <>
                      <p>
                        Metal buildings are a great investment that you can make
                        at a fraction of the cost of a wood building, and they
                        offer superior protection and security. Quality-built
                        carports, garages, barns, and more are available from
                        Coast To Coast Carports, Inc. throughout the state of{" "}
                        {pageData.name}. Our steel buildings are some of the
                        best you’ll find anywhere, and they also come at the
                        best price!
                      </p>
                      <p>
                        At Coast to Coast Carports, we provide a wide range of
                        high-quality metal buildings, including garages, barns,
                        carports, RV covers, metal sheds, and more. These metal
                        buildings in {pageData.name} can withstand snowfall,
                        heavy rains, termites, lightning, high winds, and even
                        fire because they are non-combustible in nature. They
                        are strong enough to take tornados and earthquakes.
                        These durable steel buildings in {pageData.name} can be
                        used as storage space, parking space, hobby space,
                        entertainment area, and much more. They are becoming the
                        popular and perfect choice for agricultural,
                        residential, commercial, and industrial purposes. So, if
                        you are looking for a variety of buildings which are
                        long-lasting, call our building experts at{" "}
                        <a href="tel:8666817846" aria-label="(866) 681-7846">
                          (866) 681-7846
                        </a>{" "}
                        they will guide you throughout the process and will help
                        you find the perfect structure according to your needs.
                      </p>

                      <h2>Features of Metal Buildings in {pageData.name}:</h2>
                      <h3>Multipurpose Steel Buildings:</h3>
                      <p>
                        Looking for the best storage option to keep your
                        vehicles, equipment, and other valuable items safe from{" "}
                        {pageData.name}’s weather swings? Coast To Coast
                        Carports is one of the leading suppliers of metal
                        buildings in the US, with four manufacturing facilities
                        dedicated to producing some of the finest metal
                        structures on the market. From steel carports to garages
                        to barns to warehouses, we can take care of you. No
                        project is too big or too small for our friendly team of
                        experts!
                      </p>

                      <h3>Great Selection Of Metal Building Options:</h3>
                      <p>
                        Although our name is Coast To Coast Carports, we are
                        proud to offer much more than just carports. If it’s a
                        metal framed-and-roofed structure you need, we can do
                        it! Here’s just a sampling of the steel buildings we can
                        custom make, deliver, and install for you:
                      </p>
                    </>
                  )}

                {pageData.abbreviation === "AL" && (
                  <>
                    <p>
                      Metal buildings are a long-lasting, affordable way to
                      protect your vehicles and belongings from Alabama’s
                      volatile weather. Our structures are designed to stand up
                      to high winds, and provide you with years of service in
                      regions that regularly see volatile storms, tornados, and
                      hurricanes.
                    </p>
                    <p>
                      Coast to Coast Carports is a leading provider of metal
                      buildings in Alabama, and we’ve got a structure that’s
                      just right for you. Whether you’re looking for a small
                      carport, a large commercial garage, or a metal barn to
                      protect your farm valuables, our metal buildings are ready
                      for any job in Yellowhammer State.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "AZ" && (
                  <>
                    <p>
                      Metal buildings are the perfect solution for protecting
                      your vehicles and belongings from the extreme weather
                      conditions in Arizona. They are engineered to withstand
                      intense heat, heavy winds, and occasional monsoons,
                      providing long-term protection for your assets.
                    </p>
                    <p>
                      At Coast to Coast Carports, we are proud to be your
                      premier source for metal buildings in Arizona. Our range
                      of structures includes residential carports, garages,
                      barns, and sheds. Our commercial building range includes
                      warehouse buildings, workshop buildings, clear-span
                      structures, large buildings, and agricultural buildings.
                      No matter what your needs are, our steel buildings are up
                      to the task.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "AR" && (
                  <>
                    <p>
                      When it comes to protecting your stuff from Arkansas's
                      wild weather, metal buildings are the way to go. Our metal
                      buildings are tough enough to handle the storms, heavy
                      rain, and strong winds that Arkansas throws your way.
                      They're perfect for any residential, commercial, or
                      agricultural use.
                    </p>
                    <p>
                      At Coast to Coast Carports, we're all about quality. Our
                      metal buildings are manufactured by experts with top-notch
                      steel and designed to tackle your toughest jobs. Whether
                      you need a garage, barn, storage building, colonial
                      building, warehouse building, commercial structure, or
                      custom building, we've got you covered.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "CA" && (
                  <>
                    <p>
                      Looking for a reliable way to safeguard your assets from
                      California's unpredictable weather? Well, look no further!
                      Metal buildings are the answer. These tough structures are
                      built to withstand everything from coastal winds, heavy
                      rain, and wild fires to seismic activity – making them a
                      go-to choice for any resident of the Golden State.
                    </p>
                    <p>
                      At Coast to Coast Carports, we specialize in top-quality
                      metal buildings in California. Our buildings are
                      precision-engineered by experts. Whether you need a small
                      backyard shed, storage building, commercial carport or
                      garage, custom building, or a sturdy shelter for your
                      agricultural equipment, our steel buildings are up to the
                      task.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "CO" && (
                  <>
                    <p>
                      Metal buildings are the best way to safeguard your
                      valuables from Colorado's unique weather conditions. They
                      are built to endure heavy snow, high winds, and intense
                      sun, providing lasting security for your vehicles and
                      other belongings. Our buildings are capable, adaptable,
                      and ready to take on any residential, agricultural, or
                      commercial project.
                    </p>
                    <p>
                      With years of industry experience and a dedication to
                      quality craftsmanship, Coast to Coast Carports is proud to
                      be your premier choice for metal buildings in Colorado.
                      Our building range includes carports, garages, clear span,
                      commercial, colonial, and custom buildings.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "FL" && (
                  <>
                    <p>
                      Metal buildings are a smart choice for keeping your
                      vehicles, equipment, and valuables safe from Florida's
                      unpredictable weather. These structures are engineered to
                      withstand high winds and humidity, offering years of
                      reliable service in one of the toughest climates in the
                      US.
                    </p>
                    <p>
                      Coast to Coast Carports stands out as a premier provider
                      of metal buildings in Florida, offering a range of options
                      tailored to your needs. From upgrading your garage to
                      shielding your farm equipment from the elements, our metal
                      structures are designed to tackle any job in the Sunshine
                      State. Whether you need carports, metal barns, custom
                      buildings, or workshops, we have the perfect solution for
                      you.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "IL" && (
                  <>
                    <p>
                      Metal buildings offer a cost-effective and long-lasting
                      solution to protect your possessions from Illinois's
                      extreme weather. Built to withstand heavy snow
                      accumulation and strong winds, these structures are up to
                      the task for even your biggest, most demanding jobs.
                    </p>
                    <p>
                      At Coast to Coast Carports, we take pride in being a
                      leading provider of metal buildings in Illinois. Whether
                      you're expanding your storage space with a shed or
                      shielding your equipment with a custom metal building, our
                      structures are ready to work for you. We offer a range of
                      options, including carports, garages, and workshops,
                      designed to meet your specific needs and keep your assets
                      safe.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "IN" && (
                  <>
                    <p>
                      Protect your vehicles and belongings with an Indiana metal
                      building. These structures are designed to endure heavy
                      snowfall, strong winds, and severe storms, keeping your
                      most important things safe when Mother Nature doesn't
                      cooperate.
                    </p>
                    <p>
                      Coast to Coast Carports is the go-to choice for metal
                      buildings in Indiana, offering structures uniquely built
                      to meet your needs. Whether you need a new garage, a
                      sturdy metal barn, or a spacious workshop, we have the
                      perfect solution. Our metal buildings are prepared to
                      handle any project in the Hoosier State, ensuring your
                      assets stay protected in all weather conditions.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "KS" && (
                  <>
                    <p>
                      Ensuring the safety of your cars, equipment, and
                      belongings in Kansas is essential, especially with the
                      state's challenging weather conditions, including violent
                      storms, high winds, and tornadoes. Our metal buildings are
                      designed to withstand these harsh elements, keeping your
                      valuables secure.
                    </p>
                    <p>
                      Coast to Coast Carports is your top choice for metal
                      buildings in Kansas. Whether you're looking to expand your
                      storage with a shed, upgrade your farm with a sturdy metal
                      barn, or set up a new workshop, our metal buildings are
                      equipped to handle any project in the Sunflower State.
                      We've got the perfect solution for all your needs, be it
                      residential, commercial, or agricultural.{" "}
                    </p>
                  </>
                )}
                {pageData.abbreviation === "KY" && (
                  <>
                    <p>
                      Steel buildings in Kentucky are an excellent choice for
                      safeguarding your assets in any weather and season. Built
                      to withstand heavy rain, strong winds, and heavy snow,
                      these structures are ready for any job or application.
                    </p>
                    <p>
                      Enhance your home storage with a durable shed, or upgrade
                      your farm's infrastructure with a sturdy metal barn.
                      Whether it's for work or play, Coast to Coast Carports is
                      proud to be your premier source for metal buildings in
                      Kentucky. From garages and workshops to custom buildings
                      and commercial structures, we have the perfect solution
                      for your needs.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "LA" && (
                  <>
                    <p>
                      Think of a sturdy shelter that keeps your valuables safe,
                      no matter what Louisiana's weather throws your way. Our
                      metal buildings are tough enough to handle heavy rain,
                      intense humidity, and strong winds, ensuring your
                      important items are well-protected.
                    </p>
                    <p>
                      Now, picture this: your cars, motorcycles, or RVs
                      comfortably tucked away in a durable metal garage, or your
                      farm equipment stored securely in a strong metal barn. At
                      Coast to Coast Carports, we offer a variety of top-notch
                      metal buildings—garages, workshops, and custom options—all
                      designed to safeguard your belongings against the
                      elements.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "MS" && (
                  <>
                    <p>
                      Steel buildings are your best bet when it comes to
                      protecting your assets from the varied weather conditions
                      in Mississippi. These structures are built tough and
                      capable of withstanding heavy rains, high winds, and humid
                      conditions, all while being flexible enough to adapt to
                      whatever job you have planned. You can count on them to
                      deliver solid performance where it counts.
                    </p>
                    <p>
                      At Coast to Coast Carports, we're a trusted name across
                      Mississippi for a variety of metal buildings. Looking to
                      upgrade your garage? Need to protect your tools and
                      equipment? Or maybe you're thinking about expanding your
                      storage with a new shed or warehouse? Our structures are
                      engineered with top-quality components and built to
                      endure. No matter your project in the Magnolia State, our
                      steel buildings are prepared to meet the challenge.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "MO" && (
                  <>
                    <p>
                      Metal buildings are a long-lasting, cost-effective choice
                      for safeguarding your cars, trucks, boats, and RVs.
                      Designed to stand up to Missouri's heavy snowfall and
                      strong winds, they provide reliable protection for your
                      most valuable possessions.
                    </p>
                    <p>
                      At Coast to Coast Carports, we specialize in providing
                      top-tier metal buildings to residents of Missouri. Whether
                      you're looking to expand your storage space with a new
                      shed or warehouse, upgrade your farm with a robust metal
                      barn, or kickstart a new business in a custom building,
                      our structures are ready to meet any challenge. Let us
                      help you find the perfect solution for your needs!
                    </p>
                  </>
                )}
                {pageData.abbreviation === "MI" && (
                  <>
                    <p>
                      Stepping out into Michigan's ever-changing weather, you
                      can rest assured knowing your valuables are perfectly
                      shielded. Steel buildings offer a sturdy, reliable guard
                      against heavy snow, fierce winds, and unpredictable
                      temperature swings, providing years of peace of mind.
                    </p>
                    <p>
                      At Coast to Coast Carports, we're all about durability and
                      versatility. Whether you need to expand your storage space
                      or protect your machinery, our selection is designed to
                      meet your needs. Our range includes spacious garages,
                      sturdy metal barns, expansive clear-span buildings, and
                      customized workshops—all crafted from premium steel. Each
                      structure is engineered to support a variety of projects,
                      be they residential, agricultural, or commercial, ensuring
                      you find the perfect fit right here in Michigan.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "NM" && (
                  <>
                    <p>
                      Metal buildings are a top choice for keeping your
                      valuables secure against New Mexico's diverse weather
                      conditions. These robust structures are crafted to endure
                      high winds and intense sunlight, ensuring your most
                      important assets are well-protected.
                    </p>
                    <p>
                      At Coast to Coast Carports, we're known across New Mexico
                      for our high-quality metal buildings. Constructed with
                      premium components, our buildings are engineered to handle
                      everything from residential garages and workshops to
                      commercial structures and deluxe buildings. Trust us to
                      provide the security and durability you need to keep your
                      property safe.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "NY" && (
                  <>
                    <p>
                      Metal buildings in NY are a reliable way to keep your
                      cars, trucks, and recreational vehicles in top condition.
                      Engineered to withstand heavy snowfall and strong winds,
                      these structures are versatile enough to adapt to any use,
                      from urban storage solutions to rural protection for farm
                      animals.
                    </p>
                    <p>
                      Whether you’re in the bustling streets of NYC or the
                      tranquil countryside upstate, Coast to Coast Carports has
                      a building that fits your needs. We’re proud to offer a
                      wide range of metal structures in New York, including
                      garages, barns, workshops, and storage sheds.
                    </p>
                    <p>
                      Crafted with high-quality steel, our buildings are
                      designed to stand the test of time, providing protection
                      and flexibility for whatever project you have in mind. Let
                      us help you find the perfect solution to keep your
                      property safe and secure in the Empire State.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "NC" && (
                  <>
                    <p>
                      Metal buildings in North Carolina offer a sturdy and
                      cost-effective solution to protect your vehicles and
                      valuables from the state's varied weather. Built to endure
                      heavy rain, strong winds, and intense summer heat, these
                      structures promise years of dependable service.
                    </p>
                    <p>
                      At Coast to Coast Carports, we're well-known throughout
                      North Carolina for our robust selection of metal
                      buildings. Looking to expand your garage, or perhaps you
                      need a secure shelter for your equipment? Our lineup
                      includes everything from carports and garages to metal
                      barns and custom buildings, all designed to meet the
                      diverse needs of the Tar Heel State. Let us help you find
                      the perfect structure to tackle any challenge.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "OH" && (
                  <>
                    <p>
                      Metal buildings in Ohio are all about resilience and
                      reliability, making them perfect for all your residential,
                      commercial, and agricultural projects. Built to endure
                      whatever weather comes your way—be it intense winds or
                      heavy snow—these structures are made to last.
                    </p>
                    <p>
                      At Coast to Coast Carports, we specialize in creating
                      metal buildings that fit a variety of needs across Ohio.
                      Whether you're looking to boost your business storage
                      space with a new commercial building or warehouse, or need
                      a durable shelter for your equipment, our metal buildings
                      are up to the task. Consider investing in an Ohio metal
                      building today to protect what matters most to you.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "OK" && (
                  <>
                    <p>
                      Metal buildings are a real game-changer when it comes to
                      standing up to Oklahoma's dynamic weather. They're built
                      tough to handle heavy rain, high winds, and yes, even
                      those frequent tornadoes.
                    </p>
                    <p>
                      Coast to Coast Carports is your go-to spot in Oklahoma for
                      top-notch metal structures. Crafted with high-quality
                      components, these buildings are engineered to protect
                      everything from your vehicles and equipment to your most
                      cherished valuables. Need more room for your stuff or
                      thinking about enhancing your farm setup? From spacious
                      warehouses and rugged metal barns to functional garages
                      and stylish barndominiums, our selection is ready to meet
                      your needs and exceed your expectations. What’s your next
                      big project? Let’s make it happen with the right building!
                    </p>
                  </>
                )}
                {pageData.abbreviation === "PA" && (
                  <>
                    <p>
                      Metal buildings are your go-to for more than just keeping
                      your valuables safe—they're about making space for your
                      life in Pennsylvania. Whether it's a garage expansion or a
                      new workshop where your creativity can thrive, these
                      structures are built to last and designed with your needs
                      in mind.
                    </p>
                    <p>
                      Coast to Coast Carports is well-known from Pittsburgh to
                      Philadelphia for its wide range of metal buildings. Maybe
                      you're thinking about a sleek new barndominium or a custom
                      building that perfectly fits your business. We also offer
                      specialized structures like commercial buildings and
                      deluxe buildings that blend style with functionality.
                      What's your vision for your space? Let's make it a reality
                      with a structure that truly suits your needs.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "SC" && (
                  <>
                    <p>
                      Metal buildings in South Carolina are top-notch when it
                      comes to protecting your vehicles, equipment, and
                      valuables. Engineered to withstand high winds and
                      flooding, they're an ideal choice in a state that
                      frequently faces hurricanes and tropical storms.
                    </p>
                    <p>
                      Whether you live in Rock Hill or Charleston, Coast to
                      Coast Carports stands out as a leading provider of metal
                      structures. Our buildings are tailored to handle any
                      residential, agricultural, or commercial project. Crafted
                      with high-quality steel components and backed by
                      industry-leading warranties, they're built to last and
                      offer the reliable protection you need. What project are
                      you thinking about? Let's find the perfect metal building
                      to meet your needs and exceed your expectations.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "TN" && (
                  <>
                    <p>
                      Metal buildings in Tennessee are a smart, cost-effective
                      choice for keeping your belongings safe and sound.
                      Designed to stand strong against heavy rain, gusty winds,
                      and significant snowfall, these structures provide the
                      reliable protection you need without straining your
                      budget.
                    </p>
                    <p>
                      Looking to boost your storage capabilities or safeguard
                      your machinery? Coast to Coast Carports specializes in
                      providing a variety of metal buildings that suit any need.
                      Our range includes everything from clear-span buildings to
                      metal barns and commercial structures. If you're in
                      Tennessee and need a building that can handle all the
                      residential, commercial, and agricultural projects, let's
                      chat about finding the right structure.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "TX" && (
                  <>
                    <p>
                      Metal buildings in Texas are an excellent solution for
                      protecting your valuables from the state’s extreme
                      weather. The region regularly sees high winds, vicious
                      storms, and even the real threat of hurricanes and
                      tornados, so having the right infrastructure matters.
                    </p>
                    <p>
                      These steel buildings are engineered to hold up to these
                      weather patterns and work for any purpose or application.
                      From residential metal buildings to commercial structures,
                      there's a building for every Texas-sized job.
                    </p>
                    <p>
                      Coast to Coast Carports is the leading provider of metal
                      buildings in Texas. Whether you’re enhancing your garage
                      or safeguarding farm valuables from the elements, our
                      metal buildings are ready for any challenge in the Lone
                      Star State.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "UT" && (
                  <>
                    <p>
                      Metal buildings in Utah are the way to go if you're
                      looking for a durable, cost-effective solution to protect
                      your vehicles and belongings. These structures are built
                      to handle everything the Utah environment throws at
                      them—from heavy snow and strong winds to intense heat.
                      Plus, they're versatile enough for any use you can
                      imagine.
                    </p>
                    <p>
                      Whether you're thinking about upgrading your garage,
                      adding a custom workshop, or setting up a new metal barn
                      to shelter equipment, Coast to Coast Carports has got you
                      covered. We're known throughout Utah for our expertise in
                      metal buildings. With years of experience under our belt,
                      we're dedicated to delivering top-quality products. Ready
                      to tackle any challenge in the Beehive State? Let's talk
                      about how our metal buildings can meet your specific
                      needs.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "VA" && (
                  <>
                    <p>
                      Metal buildings are a sturdy choice for anyone in Virginia
                      looking to protect their assets from the often challenging
                      local climate. Whether it's shielding the family car from
                      high winds and rain, or beefing up the farm against heavy
                      snowfall, the right structure makes all the difference.
                    </p>
                    <p>
                      Here at Coast to Coast Carports, we're well-known
                      throughout Virginia for our wide array of metal buildings.
                      Our lineup includes everything from carports and garages
                      to metal barns and commercial buildings, all designed to
                      meet a variety of needs. Each structure is built with
                      high-quality steel components, ensuring they're up to the
                      task of handling the toughest jobs in the state. What kind
                      of protection or upgrade are you thinking about? Let's
                      find the perfect metal building for your project!
                    </p>
                  </>
                )}
                {pageData.abbreviation === "WA" && (
                  <>
                    <p>
                      If you're in Washington and want to keep your valuables
                      safe from the state's extreme weather, metal buildings are
                      the way to go. They're built to last and capable of
                      withstanding heavy rainfall, strong winds, and fluctuating
                      temperatures, making your life a little simpler by keeping
                      your things secure.
                    </p>
                    <p>
                      Looking to expand your garage? Or maybe you need to
                      safeguard agricultural equipment on your farm? Coast to
                      Coast Carports is recognized throughout Washington for its
                      diverse range of metal structures. We have everything from
                      sturdy garages and spacious sheds to durable metal barns
                      and clear-span buildings, all ready to meet any challenge
                      in the Evergreen State. What's your project? Let's make
                      sure you have the right building for the job!
                    </p>
                  </>
                )}
                {pageData.abbreviation === "WV" && (
                  <>
                    <p>
                      Built to endure heavy snow, strong winds, and fluctuating
                      temperatures, metal buildings provide a robust,
                      cost-effective way to protect your belongings from West
                      Virginia’s weather. They're created to handle high
                      mountain winds and heavy snow accumulation, keeping your
                      things safe when you need them most.
                    </p>
                    <p>
                      Coast to Coast Carports is proud to be your premier source
                      for metal buildings in West Virginia. We have an extensive
                      lineup of structures to suit any residential,
                      agricultural, or commercial job, and we've got one that's
                      perfectly suited for you. No matter what type of work or
                      play you get into, our metal buildings are designed for
                      any challenge in the Mountain State.
                    </p>
                  </>
                )}
                {pageData.abbreviation === "OR" && (
                  <>
                    <p>
                      Metal buildings in Oregon are a sturdy, economical way to
                      protect your vehicles and belongings. They’re engineered
                      to withstand heavy rain, strong winds, and fluctuating
                      temperatures and offer long-lasting security in any
                      weather.
                    </p>
                    <p>
                      Coast to Coast Carports is a leading provider of metal
                      buildings in Oregon, with a distinct lineup of structures
                      that are ready for any job. Whether you’re enhancing your
                      vehicle storage capacity or protecting work equipment from
                      harsh weather, our metal buildings are ready for any task
                      in Beaver State.
                    </p>
                  </>
                )}

                <ListBadge>
                  <Link to="/metal-carports" className="badge-white">
                    <Badge badgeText="Carports" />
                  </Link>
                  <Link to="/metal-garages" className="badge-white">
                    <Badge badgeText="Garages" />
                  </Link>
                  <Link to="/metal-barns" className="badge-white">
                    <Badge badgeText="Barns" />
                  </Link>
                  <Link to="/metal-sheds" className="badge-white">
                    <Badge badgeText="Sheds" />
                  </Link>
                  <Link to="/storage-units" className="badge-white">
                    <Badge badgeText="Storage Units" />
                  </Link>
                  <Link to="/rv-carports" className="badge-white">
                    <Badge badgeText="RV Covers" />
                  </Link>
                  <Link to="/loafing-sheds" className="badge-white">
                    <Badge badgeText="Loafing Sheds" />
                  </Link>
                  <Link to="/farm-buildings" className="badge-white">
                    <Badge badgeText="Farm Buildings" />
                  </Link>
                  <Link to="/utility-carports" className="badge-white">
                    <Badge badgeText="Utility Carports" />
                  </Link>
                  <Link to="/workshop-buildings" className="badge-white">
                    <Badge badgeText="Workshops" />
                  </Link>
                  <Link to="/warehouses" className="badge-white">
                    <Badge badgeText="Warehouses" />
                  </Link>
                  <Link to="/clear-span-buildings" className="badge-white">
                    <Badge badgeText="Clear Span Buildings" />
                  </Link>
                  <Link to="/custom-buildings" className="badge-white">
                    <Badge badgeText="Custom Buildings" />
                  </Link>
                  <Link to="/colonial-buildings" className="badge-white">
                    <Badge badgeText="Colonial-Style Buildings" />
                  </Link>
                  <Link to="/products" className="badge-white">
                    <Badge badgeText="And more!" />
                  </Link>
                </ListBadge>
              </LeftContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                {/* {(pageData.abbreviation === "AR" || pageData.abbreviation === "IL" || pageData.abbreviation === "NM" || pageData.abbreviation === "CO" || pageData.abbreviation === "ID") && <StateSale state={pageData.abbreviation} /> } */}
                <PrimaryForm
                  states={data.allContentfulState.edges}
                  location={location}
                />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle data={pageData.locationsForMainBuildingCategories} />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">
            Explore {pageData.name}’s Metal Buildings
          </SectionTitle>
        </div>
        <LikeProductCarousel
          relatedProductsData={relatedProductsData}
          openQuoteModal={() => setQuoteVisible(true)}
        />
      </Section>

      {(pageData.abbreviation === "ID" ||
        pageData.abbreviation === "TX" ||
        pageData.abbreviation === "TN" ||
        pageData.abbreviation === "OR") && (
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle state={pageData.name} />
          </div>
        </Section>
      )}
      {/* <Section pt="90px" pb="90px" xpt="30px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle textAlign="left" maxWidth="910px">
            Roof Style Options For Your {pageData.name} Steel Building
          </SectionTitle>
          <MarkdownContent maxWidth="910px" mb="30px" textAlign="left">
            <p>
              Choose from the following three styles of roof for your{" "}
              {pageData.name}
              metal building from Coast To Coast Carports:
            </p>
            <h3>Regular (Rounded Corners) Style – GOOD</h3>
            <p>
              This roof style is the most economical. The corrugated ridges run
              from end to end. This roof style is great if you live in an area
              that doesn’t typically experience heavy wind or rain. A regular
              roof is perfect for protecting your vehicles and other items from
              excessive UV exposure.
            </p>

            <h3>A-Frame Horizontal – BETTER</h3>
            <p>
              Also known as a boxed eave roof, this is an A-frame style with
              roof panels that are installed horizontally. This is a stronger
              design than the regular roof, and it performs better for areas
              that do receive some stronger winds, but not excessive
              precipitation.
            </p>

            <h3>A-Frame Vertical – BEST</h3>
            <p>
              This is an A-frame style with panels that are installed
              vertically, so rain and snow can glide down and away from your
              building more easily. The vertical roof also comes with
              hat-channel pieces and more reinforcement, and is our strongest
              roof design option. If you live in area that experiences heavy
              rain or snowfall, or if your building is longer than 36’, we
              highly recommend that you go with a vertical roof.
            </p>
            <p>
              Because {pageData.name} experiences a range of weather conditions,
              we recommend the extra durability and strength of a vertical roof
              for all of our {pageData.name} metal building customers. You can
              also choose from either front or side-entry options for your
              carport, garage, or other metal building, depending on the layout
              of your install site and on your convenience and preferences.
            </p>
          </MarkdownContent>
        </div>
      </Section> */}
      {/* <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionEditor>
            <SectionEditorLeft>
              <h2>
                Metal Buildings {pageData.name} – Steel Buildings{" "}
                {pageData.name} Installation:
              </h2>
            </SectionEditorLeft>
            <SectionEditorRight>
              <p>
                With Coast To Coast Carports, you don’t have to worry with the
                hassle of figuring out how to get the building to your site, or
                with the headache of trying to put it up yourself. We are happy
                to provide full delivery and installation services! All you need
                to do is ensure that your installation site is level before we
                arrive with your building. Let the experts at Coast To Coast
                Carports take care of the rest!
              </p>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section> */}
      {/* <Section
        pt="140px"
        pb="140px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <SectionOverlayBg>
          <StaticImage src="../images/purchase-bg.jpg" alt="purchase" />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>
                Why Choose Coast to Coast for Metal Buildings in {pageData.name}
                :
              </h2>
              <p>
                From our local manufacturing facilities in AR, TX, ID, and OR,
                Coast To Coast Carports stands ready to take care of all your{" "}
                {pageData.name} metal building and metal building kit needs. We
                can deliver and install your steel building anywhere in{" "}
                {pageData.abbreviation}, and we’ll do the job right for you the
                first time!
              </p>
              <p>
                Coast To Coast can construct and install everything from
                carports to RV covers to sheds to garages to storage units to
                workshops to warehouses. We’ve been in business since 2001,
                helping customers in {pageData.name} and across the country to
                get the prefab, custom-engineered metal structure they need to
                meet all their residential, commercial, or agricultural
                purposes.
              </p>
              <p>
                Reach out to us at Coast To Coast Carports today, and let us
                show you why we’re the best in {pageData.abbreviation}!
              </p>
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section> */}
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle mb="0">Areas We Serve In {pageData.name}</SectionTitle>
          <SectionLead mb="40px" maxWidth="900px">
            We cover all locations throughout the state of {pageData.name},
            including:
          </SectionLead>
          <ListAreas>
            {pageData.cities.slice(0, limit).map(item => {
              return <ListItem key={v4()}>{item.content}</ListItem>
            })}
          </ListAreas>
          {limit !== pageData.cities.length && (
            <LoadMore onClick={() => Setlimit(pageData.cities.length)}>
              <PrimaryButton text="Load More" />
            </LoadMore>
          )}
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default State

export const pageQuery = graphql`
  query MetalBuildingsStateLandingQuery($id: String!, $name: String!) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      locationsForMainBuildingCategories {
        metalBuildingAddress
        metalBuildingContactNumber
        metalBuildingLocationLink
        metalBuildingMapEmbedLink {
          metalBuildingMapEmbedLink
        }
      }
      cities {
        content
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationsForMainBuildingCategories {
            metalBuildingAddress
            metalBuildingContactNumber
            metalBuildingLocationLink
            metalBuildingMapEmbedLink {
              metalBuildingMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: { installationStates: { elemMatch: { name: { glob: $name } } } }
    ) {
      edges {
        node {
          id
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          width
          height
          length
          price
        }
      }
    }
  }
`
