import React, { useState } from "react"
import { Wrapper } from "./styles"
import QuotePopup from "../QuotePopup"
import ProductCard from "../ProductCard"

const ProductList = ({ products, openQuoteModal, location }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  return (
    <Wrapper>
      {!!products &&
        products.map((item, i) => {
          if (i >= 10) return null
          return (
            <ProductCard
              data={item.node}
              openQuoteModal={openQuoteModal}
              key={i}
            />
          )
        })}

      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Wrapper>
  )
}

export default ProductList
