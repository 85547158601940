import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
    Accordions,
    Accordionsitems,
    AccordionsContent,
    AccordionsContentinner,
    AccordionsTitle,
} from "../Accordion"

const ListLinks = styled.ul`
    list-style: none;
    margin:0 0 30px;
    padding:0;

    column-count: 1;
    column-gap: 5px;
    @media (min-width: 567px) {
        column-count: 2;
    }
    @media (min-width: 768px) {
        column-count: 3;
    }
`
const ListItemLinks = styled.li`
    margin:0;
    line-height: 26px;
    > a{        
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color:#333;
        &:hover{
            color:#000;
        }
    }
    + li{
        margin-top:15px;
    }
`

const SitemapAccordion = ({ products, states }) => {    
    const [activeAccordion, setActiveAccordion] = useState(null)
    const [activeSubAccordion, setActiveSubAccordion] = useState(null)
    function handleAccodion(index) {
      if (activeAccordion === index) {
        return setActiveAccordion(null)
      }
      setActiveAccordion(index)
    }

    function handleInnerAccodion(index) {
      if (activeSubAccordion === index) {
        return setActiveSubAccordion(null)
      }
      setActiveSubAccordion(index)
    }

	return (
        <Accordions className="accordions">
            <Accordionsitems className={`accordions-items ${activeAccordion === "sitemap" ? "active" : "" }`}>
                <AccordionsTitle className="accordions-title" onClick={() => handleAccodion("sitemap")}>Coast To Coast Sitemap<span></span></AccordionsTitle>
                <AccordionsContent className="accordions-content">
                    <AccordionsContentinner>
                        <ListLinks>
                            <ListItemLinks><Link to="/">Home</Link></ListItemLinks>
                            <ListItemLinks><Link to="/rent-to-own-buildings">Rent-To-Own</Link></ListItemLinks>
                            <ListItemLinks><Link to="/financing">Financing</Link></ListItemLinks>
                            <ListItemLinks><Link to="/3d-metal-building-color-planner">3D Color Planner</Link></ListItemLinks>
                            <ListItemLinks><Link to="/color-options">Color Samples</Link></ListItemLinks>
                            <ListItemLinks><Link to="/insulation">Insulation</Link></ListItemLinks>
                            <ListItemLinks><Link to="/service-area">Service Area</Link></ListItemLinks>
                            <ListItemLinks><Link to="/frequently-asked-questions">FAQs</Link></ListItemLinks>
                            <ListItemLinks><Link to="/articles">Blog</Link></ListItemLinks>
                            <ListItemLinks><Link to="/infographics">Infographics</Link></ListItemLinks>
                            <ListItemLinks><Link to="/what-to-expect">What to Expect</Link></ListItemLinks>
                            <ListItemLinks><Link to="/reviews">Reviews</Link></ListItemLinks>
                            <ListItemLinks><Link to="/about-us">About Us</Link></ListItemLinks>
                            <ListItemLinks><Link to="/contact">Contact Us</Link></ListItemLinks>
                            <ListItemLinks><Link to="/become-a-dealer">Become A Dealer</Link></ListItemLinks>
                            <ListItemLinks><Link to="/become-an-installer">Become An Installer</Link></ListItemLinks>
                        </ListLinks>
                    </AccordionsContentinner>
                </AccordionsContent>
            </Accordionsitems>
            <Accordionsitems className={`accordions-items ${activeAccordion === "product" ? "active" : "" }`}>
                <AccordionsTitle className="accordions-title" onClick={() => handleAccodion("product")}>Products<span></span></AccordionsTitle>
                <AccordionsContent className="accordions-content">
                    <AccordionsContentinner className="product-list">
                        <ListLinks>
                            {
                                products.map((item, i) => (
                                    <ListItemLinks key={i}><Link to={item.node.url}>{item.node.productName}</Link></ListItemLinks>        
                                ))
                            }
                        </ListLinks>
                    </AccordionsContentinner>
                </AccordionsContent>
            </Accordionsitems>
            <Accordionsitems className={`accordions-items ${activeAccordion === "building" ? "active" : "" }`}>
                <AccordionsTitle className="accordions-title" onClick={() => handleAccodion("building")}>Buildings<span></span></AccordionsTitle>
                <AccordionsContent className="accordions-content">
                    <AccordionsContentinner>
                        
                        <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "carport" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("carport")}>Carports<span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner>
                                    <ListLinks>                                        
                                        <ListItemLinks><Link to="/a-frame-horizontal-carports">A-Frame Horizontal</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/a-frame-vertical-carports">A-Frame Vertical</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/regular-style-carports">Regular Style Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/one-car-carports">One Car Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/two-car-carports">Two Car Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/three-car-carports">Three Car Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/deluxe-carports">Deluxe Enclosed Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/rv-carports">RV Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/boat-garages-sheds">Boat Garages and Sheds</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/utility-carports">Utility Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/camper-carports-shelters">Camper Carports</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-carport-prices">Metal Carport Prices</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-carport-kits">Metal Carport Kits</Link></ListItemLinks>
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems>
                        
                        
                        <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "garages" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("garages")}>Garages<span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner>
                                    <ListLinks>                                        
                                        <ListItemLinks><Link to="/a-frame-horizontal-garages">A-Frame Horizontal Garages</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/a-frame-vertical-garages">A-Frame Vertical Garages</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/regular-style-garages">Regular Style Garages</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/one-car-garages">One Car Garages</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/two-car-garages">Two Car Garages</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/three-car-garages">Three Car Garages</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-garage-prices">Metal Garage Prices</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/rv-garages">RV Garages</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-garage-kits">Metal Garage Kits</Link></ListItemLinks>
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems>
                        
                        <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "metal-buildings" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("metal-buildings")}>Metal Buildings<span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner>
                                    <ListLinks>                                        
                                        <ListItemLinks><Link to="/metal-building-homes">Metal Building Homes</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/clear-span-buildings">Clear-Span Buildings</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/colonial-buildings">Colonial Buildings</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/custom-buildings">Custom Buildings</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/commercial-buildings">Commercial Buildings</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/deluxe-buildings">Deluxe Buildings</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-sheds">Sheds</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/storage-units">Storage Buildings</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/warehouses">Warehouses</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/workshop-buildings">Workshops</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/barndominiums">Barndominiums</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-building-prices">Metal Building Prices</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-building-kits">Metal Building Kits</Link></ListItemLinks>
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems>
                        
                        <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "metal-barns" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("metal-barns")}>Metal Barns<span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner>
                                    <ListLinks>                                        
                                        <ListItemLinks><Link to="/farm-buildings">Farm Buildings</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/regular-style-barns">Regular Style Barns</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/horizontal-roof-barns">Horizontal Roof Barns</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/vertical-roof-barns">Vertical Roof Barns</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/horse-barns">Horse Barns</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/gambrel-barns">Gambrel Barns</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/raised-center-barns">Raised Center Barns</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/straight-roof-barns">Straight Roof Barns</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/loafing-sheds">Loafing Sheds</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/horse-stalls-shelters">Horse Stalls and Shelters</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/riding-arenas">Riding Arenas</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-barn-prices">Metal Barn Prices</Link></ListItemLinks>
                                        <ListItemLinks><Link to="/metal-barn-kits">Metal Barn Kits</Link></ListItemLinks>
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems>

                    </AccordionsContentinner>
                </AccordionsContent>
            </Accordionsitems>
            <Accordionsitems className={`accordions-items ${activeAccordion === "statecity" ? "active" : "" }`}>
                <AccordionsTitle className="accordions-title" onClick={() => handleAccodion("statecity")}>States<span></span></AccordionsTitle>
                <AccordionsContent className="accordions-content">
                    <AccordionsContentinner>
                        <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "metal-buildings-state" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("metal-buildings-state")}><h2 className="h5">Metal Buildings</h2><span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner className="state">
                                    <ListLinks>     
                                        {states.map((item, i) => {
                                            return (
                                                <ListItemLinks key={i}>
                                                    <Link
                                                        to={`/metal-buildings-${item.node.name
                                                        .split(" ")
                                                        .join("-")
                                                        .toLowerCase()}-${item.node.abbreviation.toLowerCase()}`}
                                                    >
                                                        {item.node.name}
                                                    </Link>
                                                </ListItemLinks>
                                            )
                                        })}
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems>

                        
                        {/* <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "metal-carports-state" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("metal-carports-state")}><h2 className="h5">Metal Carports</h2><span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner className="state">
                                    <ListLinks>     
                                        {states.map((item, i) => {
                                            return (
                                                <ListItemLinks key={i}>
                                                    <Link
                                                        to={`/metal-carports-${item.node.name
                                                        .split(" ")
                                                        .join("-")
                                                        .toLowerCase()}-${item.node.abbreviation.toLowerCase()}`}
                                                    >
                                                        {item.node.name}
                                                    </Link>
                                                </ListItemLinks>
                                            )
                                        })}
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems>
                        
                        
                        <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "metal-garage-state" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("metal-garage-state")}><h2 className="h5">Metal Garages</h2><span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner className="state">
                                    <ListLinks>     
                                        {states.map((item, i) => {
                                            return (
                                                <ListItemLinks key={i}>
                                                    <Link
                                                        to={`/metal-garages-${item.node.name
                                                        .split(" ")
                                                        .join("-")
                                                        .toLowerCase()}-${item.node.abbreviation.toLowerCase()}`}
                                                    >
                                                        {item.node.name}
                                                    </Link>
                                                </ListItemLinks>
                                            )
                                        })}
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems>

                        
                        <Accordionsitems className={`sub-accordions accordions-items ${activeSubAccordion === "rv-carports-state" ? "active" : "" }`}>
                            <AccordionsTitle className="accordions-title" onClick={() => handleInnerAccodion("rv-carports-state")}><h2 className="h5">RV Carports</h2><span></span></AccordionsTitle>
                            <AccordionsContent className="accordions-content">
                                <AccordionsContentinner className="state">
                                    <ListLinks>     
                                        {states.map((item, i) => {
                                            return (
                                                <ListItemLinks key={i}>
                                                    <Link
                                                        to={`/metal-rv-covers-${item.node.name
                                                        .split(" ")
                                                        .join("-")
                                                        .toLowerCase()}-${item.node.abbreviation.toLowerCase()}`}
                                                    >
                                                        {item.node.name}
                                                    </Link>
                                                </ListItemLinks>
                                            )
                                        })}
                                    </ListLinks>
                                </AccordionsContentinner>
                            </AccordionsContent>
                        </Accordionsitems> */}
                        
                    </AccordionsContentinner>
                </AccordionsContent>
            </Accordionsitems>
        </Accordions>
	)
}

export default SitemapAccordion
