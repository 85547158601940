import React, { Component } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"
import VerticalProductCard from "../../components/VerticalProductCard"

const SlickSlider = styled.div`
  /* flex-wrap:wrap;
  margin:0 -10px;  
  justify-content:center; */
  .slick-arrow {
    position: absolute;
    z-index: 1;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #0b619b;
    svg {
      fill: #fff;
    }
    &:hover,
    &:focus {
      background-color: #fc0002;
    }
    &.slick-disabled {
      background-color: #eeeeee;
      cursor: default;
      svg {
        fill: #999;
      }
    }

    &.slick-prev {
      left: -8px;
      border-radius: 0 50% 50% 0;
    }
    &.slick-next {
      right: -8px;
      border-radius: 50% 0 0 50%;
    }
    &:before {
      display: none;
    }
  }
`
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  position: relative;
  padding: 0 10px;
  .card {
    max-width: 624px;
    width: 100%;
    margin: 0 auto;
  }
`
// const SlickArrowTrack = styled.div`
//   /* display: none; */
//   position: absolute;
//   top: calc(50% - 45px);
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 10;
//   right: 0;
//   min-height: 30px;
//   width: 100%;
//   &:hover {
//     cursor: pointer;
//   }
// `
// const LeftArrow = styled.div`
//   position: absolute;
//   width: 36px;
//   height: 36px;
//   border-radius: 50%;
//   background-color: #fff;
//   left: -18px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   svg {
//     fill: #fc0002;
//   }
//   &:hover {
//     background-color: #0b619b;
//     svg {
//       fill: #fff;
//     }
//   }
// `
// const RightArrow = styled.div`
//   position: absolute;
//   width: 36px;
//   height: 36px;
//   border-radius: 50%;
//   background-color: #fff;
//   right: -18px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   svg {
//     fill: #fc0002;
//   }
//   &:hover {
//     background-color: #0b619b;
//     svg {
//       fill: #fff;
//     }
//   }
// `

function CircleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowRightIcon />
    </div>
  )
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowLeftIcon />
    </div>
  )
}

export default class LikeProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const settings = {
      slidesToShow: this.props.relatedProductsData.length > 2 ? 3 : 2,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: true,
      centerMode: false,
      centerPadding: "60px",
      infinite: false,
      draggable: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            centerMode: true,
            slidesToShow: 3,
            centerPadding: "5px",
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "0px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "0px",
          },
        },
      ],
    }

    const { openQuoteModal } = this.props
    if (this.props.relatedProductsData.length === 1) {
      return (
        <SlickSlider>
          <SlickItem>
            <VerticalProductCard
              productData={this.props.relatedProductsData[0]}
              openQuoteModal={openQuoteModal}
            />
          </SlickItem>
        </SlickSlider>
      )
    } else {
      return (
        <SlickSlider>
          <Slider {...settings} ref={c => (this.slider = c)}>
            {this.props.relatedProductsData.map(product => {
              return (
                <SlickItem>
                  <VerticalProductCard
                    productData={product}
                    openQuoteModal={openQuoteModal}
                  />
                </SlickItem>
              )
            })}
          </Slider>
          {/* <SlickArrowTrack>
            <LeftArrow onClick={this.previous}>
              <ArrowLeftIcon />
            </LeftArrow>
            <RightArrow onClick={this.next}>
              <ArrowRightIcon />
            </RightArrow>
          </SlickArrowTrack> */}
        </SlickSlider>
      )
    }
  }
}
