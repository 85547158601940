import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { PhoneLineIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"

const MapWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const MapLeft = styled.div`
  position: relative;
  width: 100%;  
  ${BreakpointUp.md`
    flex: 0 0 calc(58.333333% + 20px);
    max-width: calc(58.333333% + 20px);
  `}

  ${BreakpointUp.lg`
    flex: 0 0 calc(58.333333% + 35px);
    max-width: calc(58.333333% + 35px);
    
  `}
`
const MapRight = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.md`
    flex: 0 0 calc(41.666667% - 20px);
    max-width: calc(41.666667% - 20px);
  `}
  ${BreakpointUp.lg`
    flex: 0 0 calc(41.666667% - 35px);
    max-width: calc(41.666667% - 35px);
  
  `}

`
const Address = styled.address`
  margin-bottom: 0;
  font-style: inherit;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`    
    padding:50px;
  `}
  > strong {
    display: block;
    color: #0b619b;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    ${BreakpointUp.lg`    
      font-size: 24px;
      line-height: 34px;
    `}
    ${BreakpointUp.lg`    
      font-size:28px;
      line-height:38px;
      font-weight:900;
    `}
  }
`
const ListInfo = styled.ul`
  margin: 0;
  padding: 0;
  font-weight: 700;
  ${BreakpointUp.lg`            
    font-size:18px;
    font-weight:700;
  `}
`
const ListInfoItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  + li {
    margin-top: 10px;
  }
`
const AddressContainer = styled.div`
  background-color: #0b619b;
  height: 100%;
  display: flex;
  align-items: center;
  address {
    strong {
      color: #fff;
    }
    .icon {
      svg {
        fill: #fff;
      }
      + .text {
        margin-left: 15px;
      }
    }
    .text {
      color: #fff;
      &:hover{
        color:rgba(255,255,255,0.6);
      }
    }
  }
`



const LocationStyle = ({ state, city }) => {
  return(
    <MapWrap>
            <MapLeft>
                {state === "Idaho" || city === "Heyburn" ? <StaticImage src="../../images/idaho.png" alt="location" /> : ''  }             
                {state === "Texas" || city === "Amarillo" ? <StaticImage src="../../images/amarillo-tx.png" alt="location" /> : ''}
                {state === "Tennessee" || city === "Smithville" ? <StaticImage src="../../images/smithville-tennessee.png" alt="location" /> : ''}
                {state === "Oregon" || city === "Stanfield" ? <StaticImage src="../../images/oregon.png" alt="location" /> : ''}
            </MapLeft>
            <MapRight>
                <AddressContainer>
                    <Address>
                        <strong>Coast To Coast Carports, {city} {state}</strong>
                        <ListInfo>
                            <ListInfoItem>
                                <span className="icon"><PhoneLineIcon /></span>
                                <a className="text" href="tel:8666817846" aria-label="phone number">(866) 681-7846</a>
                            </ListInfoItem>
                        </ListInfo>
                    </Address>
                </AddressContainer>
            </MapRight>
        </MapWrap>      
    )
}

export default LocationStyle
