import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import BreakpointUp from "../Media/BreakpointUp"
import HeroForm from "./HeroForm"
import { HeroArrowIcon } from "../Icons"

const HeroWrap = styled.section`
  position: relative;
  padding-top: 130px;
  padding-bottom: 50px;
  ${BreakpointUp.xl` 
    padding-top: 200px;
    padding-bottom: 50px;
  `}
`

const HeroCaption = styled.div`
  position: relative;
  z-index: 1;
`

const HeroSubtitle = styled.span`
  display: block;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 576px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (min-width: 992px) {
    margin-bottom: 5px;
    font-size: 40px;
    line-height: 50px;
  }
`

const HeroTitle = styled.h1`
  text-align: center;
  color: #b30000;
  font-size: 26px;
  line-height: 36px;
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 38px;
  }
  @media (min-width: 768px) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 58px;
  }
  @media (min-width: 1200px) {
    font-size: 58px;
    line-height: 68px;
  }
  @media (min-width: 1600px) {
    font-size: 62px;
    line-height: 72px;
  }
  margin: 0 0 10px;
  @media (min-width: 1024px) {
    margin: 0;
  }
`

const SectionBackground = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const GridWrap = styled.div`
  position: relative;
  max-width: 1870px;
  align-items: center;
  margin: auto;
  padding: 0 20px;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0;
  grid-column-gap: 20px;
  @media (min-width: 1600px) {
    grid-column-gap: 30px;
    margin-top: -40px;
  }

  @media (max-width: 1400px) {
    grid-column-gap: 16px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;

  .card-image {
    position: relative;
    z-index: 1;
    width: 100%;

    .gatsby-image-wrapper {
      position: relative;
      z-index: 2;
      width: 100%;
    }
    .red-mark {
      position: absolute;
      z-index: 1;
      width: 160px;
      height: 57px;
      background: #b30000;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .text-wrap {
    margin: 40px 0 0;
    border-radius: 22px;
    padding: 3px;

    .inner-wrap {
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      text-align: center;
      padding: 15px;
    }

    h3,
    .h3 {
      font-size: 24px;
      line-height: 34px;
      color: #00243c;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      color: #00243c;
      margin: 0;
    }
  }

  &.card-1 {
    .text-wrap {
      background: linear-gradient(
        220deg,
        rgba(255, 255, 255, 0) 10.98%,
        rgba(10, 97, 155, 0.8) 118.12%
      );
    }
  }

  &.card-2 {
    flex-direction: column-reverse;

    .text-wrap {
      background: linear-gradient(
        230deg,
        rgba(10, 97, 155, 0.42) -8.37%,
        rgba(10, 97, 155, 0) 43.91%
      );
    }
    .card-image {
      margin-top: -40px;
    }
  }
  &.card-3 {
    .text-wrap {
      background: linear-gradient(
        71.01deg,
        rgba(10, 97, 155, 0.8) -18.67%,
        rgba(10, 97, 155, 0) 64.78%
      );
    }
  }

  :hover {
    &.card-1 {
      .text-wrap {
        background: linear-gradient(
          140deg,
          rgba(255, 255, 255, 0) 10.98%,
          rgba(10, 97, 155, 0.8) 118.12%
        );
      }
    }

    &.card-2 {
      .text-wrap {
        background: linear-gradient(
          130deg,
          rgba(10, 97, 155, 0.42) -8.37%,
          rgba(10, 97, 155, 0) 43.91%
        );
      }
    }
    &.card-3 {
      .text-wrap {
        background: linear-gradient(
          290deg,
          rgba(10, 97, 155, 0.8) -18.67%,
          rgba(10, 97, 155, 0) 64.78%
        );
      }
    }

    .link-button {
      background: rgba(10, 97, 155, 0.8);
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
      border-radius: 30px;
      color: #ffffff;
    }
  }
`

const GalleryButton = styled(Link)`
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 8px auto 0px;
  background: #305973;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: 800;
  padding: 6px 16px;
  border-radius: 50px;
  text-transform: uppercase;
  color: #ffffff;

  svg {
    margin-left: 7px;
  }

  :hover {
    background: rgba(10, 97, 155, 0.8);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    color: #ffffff;
  }
`

const FormCard = styled.div`
  max-width: 600px;
  width: 100%;
  margin: auto;
  border-radius: 30px;
  padding: 20px;
  backdrop-filter: blur(5px);

  background: rgba(255, 255, 255, 0.2);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1),
    inset 0px 0px 15px rgba(0, 0, 0, 0.5), 0px 4px 12px rgba(0, 0, 0, 0.25);
  border: 1px solid #fc0002;

  p {
    color: #000;
    font-family: "Heebo", sans-serif;
    margin: 0 0 10px;
    font-weight: 700;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 1440px) {
      font-size: 28px;
      line-height: 38px;
    }
    span {
      color: #b30000;
    }
  }

  form {
    .form-group-bordered {
      margin-bottom: 20px !important;

      input {
        padding-bottom: 0 !important;
        padding-left: 5px;

        &::placeholder {
          font-size: 14px;
          color: #959595;
          font-weight: 400;
          opacity: 1;
        }
      }

      select {
        padding-bottom: 0;
        padding-left: 0;

        &.label {
          color: #959595;
          font-weight: 400;
          font-size: 14px;
        }
      }
      textarea {
        padding-left: 5px;
        line-height: 1.2;

        &::placeholder {
          font-size: 14px;
          color: #959595;
          font-weight: 400;
          opacity: 1;
        }
      }
    }

    .form-action {
      text-align: center;

      button {
        padding: 5px 20px;

        span {
          text-transform: uppercase;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    margin-bottom: 30px;
    scale: 1;
  }
  @media (max-width: 640px) {
    margin: 0 20px 30px;
    width: calc(100% - 40px);
  }
`

const MobileCardList = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`

const MobileCard = styled.div`
  position: relative;
  z-index: 0;
  padding: 3px;
  border-radius: 20px;
  max-width: 600px;
  margin: 30px auto;

  @media (max-width: 640px) {
    margin: 0 20px 30px;
  }

  .inner-wrap {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    padding: 30px 20px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 20px;

    .card-image {
      position: relative;
      width: calc(50% - 15px);

      .gatsby-image-wrapper {
        margin-top: -50px;
      }
    }
    .text-wrap {
      width: calc(50% - 15px);
      text-align: center;
    }

    @media (max-width: 480px) {
      .card-image {
        width: 100%;
      }
      .text-wrap {
        width: 100%;
      }
    }
  }
  &.card-1 {
    background: linear-gradient(
      220deg,
      rgba(255, 255, 255, 0) 10.98%,
      rgba(10, 97, 155, 0.8) 118.12%
    );
  }

  &.card-2 {
    background: linear-gradient(
      230deg,
      rgba(10, 97, 155, 0.42) -8.37%,
      rgba(10, 97, 155, 0) 43.91%
    );

    .inner-wrap {
      flex-direction: row-reverse;
    }
  }
  &.card-3 {
    background: linear-gradient(
      71.01deg,
      rgba(10, 97, 155, 0.8) -18.67%,
      rgba(10, 97, 155, 0) 64.78%
    );
  }

  h3,
  .h3 {
    font-size: 24px;
    line-height: 1.5;
    color: #00243c;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    marign: 0;
    color: #00243c;
  }
`

export default class HomeHero extends Component {
  render() {
    const { data, location } = this.props
    return (
      <HeroWrap>
        <SectionBackground>
          <StaticImage
            src="../../images/hero-bg.jpg"
            alt="hero section background"
          />
        </SectionBackground>
        <HeroCaption>
          <div className="container">
            <HeroSubtitle>{data.label}</HeroSubtitle>
            <HeroTitle>{data.title}</HeroTitle>
          </div>
        </HeroCaption>
        <GridWrap>
          {data.features.map((feature, i) => (
            <FeatureCard className={`card-${i + 1}`} key={i}>
              <div className="card-image">
                <GatsbyImage
                  image={feature.image.gatsbyImageData}
                  alt={feature.image.title}
                />
                <div className="red-mark"></div>
              </div>
              <div>
                <div className="text-wrap">
                  <div className="inner-wrap">
                    <h2 className="h3">{feature.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feature.description.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
                <GalleryButton className="link-button" to={feature.buttonLink}>
                  {feature.buttonText} <HeroArrowIcon />
                </GalleryButton>
              </div>
            </FeatureCard>
          ))}
          <FormCard>
            <p>
              Get Started Today With Your <span>Free Quote</span>
            </p>
            <HeroForm location={location} />
          </FormCard>
        </GridWrap>
        <MobileCardList>
          <FormCard>
            <p>
              Get Started Today With Your <span>Free Quote</span>
            </p>
            <HeroForm location={location} />
          </FormCard>
          {data.features.map((feature, i) => (
            <MobileCard className={`card-${i + 1}`} key={i}>
              <div className="inner-wrap">
                <div className="h3">{feature.title}</div>
                <div className="card-image">
                  <GatsbyImage
                    image={feature.image.gatsbyImageData}
                    alt={feature.image.title}
                  />
                  <div className="red-mark"></div>
                </div>
                <div className="text-wrap">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: feature.description.childMarkdownRemark.html,
                    }}
                  />
                  <GalleryButton
                    className="link-button"
                    to={feature.buttonLink}
                  >
                    {feature.buttonText} <HeroArrowIcon />
                  </GalleryButton>
                </div>
              </div>
            </MobileCard>
          ))}
        </MobileCardList>
      </HeroWrap>
    )
  }
}
